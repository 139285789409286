<template>
    <div class="managementPage">
        <section class="managmentBanner">
            <div class="container-fluid cstm">
                <div class="page-heading text-center pb-5 mb-3 pt-4 mt-3">
                    <h1 class="fs70 lh-150 mb-1 fw-bold">Finvasia <span class="theme-color">Management</span></h1>
                </div>
            </div>
            <div class="container cstm">
                <div class="row">
                    <div class="col-md-12">
                        <div class="position-relative">
                            <div class="finvasia-managmentrow d-flex">
                                <div class="managment-photos-col">
                                    <div class="managment-photos-wrap">
                                        <a href="javascript:void(0)" class="managment-photo-col pointer-none">
                                            <v-lazy-image class="img-fluid" height="309" width="337"
                                                title="Kanwal Inder Pal Singh"
                                                src="/assets/images/managmentTeam/kanwal-Inder-pal-singh.png"
                                                alt="Kanwal Inder Pal Singh" />
                                        </a>
                                        <div class="managment-info-wrap">
                                            <a href="https://www.linkedin.com/in/k-i-p-singh-a65b7421/" class="managment-linked-in-link" target="_blank">
                                                <img src="/assets/images/linked-icon.svg" alt="Linked In Icon">
                                            </a>
                                            <div class="managment-Name">
                                                <h2>Kanwal Inder Pal Singh</h2>
                                                <!-- <p>Chairman Finvasia</p> -->
                                                <p>Chairman <br />Finvasia</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="managment-photos-col">
                                    <div class="managment-photos-wrap">
                                        <div class="managment-photo-col">
                                            <a href="javascript:void(0)" class="managment-photo-col pointer-none">
                                                <v-lazy-image class="img-fluid" height="309" width="337"
                                                    title="Sarvjeet Singh Virk"
                                                    src="/assets/images/managmentTeam/sarvjit-singh-virk.png"
                                                    alt="Sarvjeet Singh Virk" />
                                            </a>
                                        </div>
                                        <div class="managment-info-wrap">
                                            <a href="https://www.linkedin.com/in/sarvjeetvirk/" class="managment-linked-in-link" target="_blank">
                                                <img src="/assets/images/linked-icon.svg" alt="Linked In Icon">
                                            </a>
                                            <div class="managment-Name">
                                                <h2>Sarvjeet Singh Virk </h2>
                                                <!-- <p>Co-founder & CMD Finvasia</p> -->
                                                <p>Co-founder & Chief Managing Director<br />
                                                    Finvasia</p>
                                            </div>
                                         
                                        </div>
                                      
                                    </div>
                                </div>
                                <div class="managment-photos-col">
                                    <div class="managment-photos-wrap">
                                        <div class="managment-photo-col">
                                            <a href="javascript:void(0)" class="managment-photo-col pointer-none">
                                                <v-lazy-image class="img-fluid" height="309" width="337"
                                                    title="Tajinder Pal Singh"
                                                    src="/assets/images/managmentTeam/tajinder-singh-virk.png"
                                                    alt="Tajinder Pal Singh" />
                                            </a>
                                        </div>
                                        <div class="managment-info-wrap">
                                            <a href="https://www.linkedin.com/in/nattyvirk/" class="managment-linked-in-link" target="_blank">
                                                <img src="/assets/images/linked-icon.svg" alt="Linked In Icon">
                                            </a>
                                            <div class="managment-Name">
                                                <h2>Tajinder Virk</h2>
                                                <!-- <p>Co-founder & CEO Finvasia</p> -->
                                                <p>Co-founder & Chief Executive Officer <br />
                                                    Finvasia</p>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="ourLeader-section">
            <div class="container cstm">
                <div class="row">
                    <div class="col-md-12">
                        <div class="page-heading text-center">
                            <h2 class="fs-50 fw-bold">Our <span class="theme-color">Leadership</span></h2>
                            <!-- <p class="lh-190">That a reader will be distracted by the readable content of a page when looking at its  readable It is a established fact that a reader will </p> -->
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center top_row">
                    <div class="col-lg-3 col-md-4 col-12">
                        <div class="ourDirectorsCol bg-light-brown">
                            <div class="ourDirectors-img">
                                <v-lazy-image class="img-fluid" width="250" height="216"
                                    src="/assets/images/ourleadershipimg/RamneekGhotra.png" alt="Ramneek Ghotra"
                                    title="Ramneek Ghotra" />
                            </div>
                            <div class="managment-info-wrap">
                                <a href="https://www.linkedin.com/in/ramneek-ghotra-4b4765128/" class="managment-linked-in-link" target="_blank">
                                    <img src="/assets/images/linked-icon.svg" alt="Linked In Icon">
                                </a>
                                <div class="managment-Name">
                                    <h2>Ramneek Ghotra</h2>
                                    <p>Executive Director,<br> Growth 
                                        Finvasia</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-12">
                        <div class="ourDirectorsCol bg-light-green">
                            <div class="ourDirectors-img">
                                <v-lazy-image class="img-fluid" width="250" height="216"
                                    src="/assets/images/ourleadershipimg/Reet.png" alt="Reet Virk" title="Reet Virk" />
                            </div>
                            <div class="managment-info-wrap">
                                <a href="https://www.linkedin.com/in/ramneek-virk-a08878200/" class="managment-linked-in-link" target="_blank">
                                    <img src="/assets/images/linked-icon.svg" alt="Linked In Icon">
                                </a>
                                <div class="managment-Name">
                                    <h2>Reet Virk</h2>
                                    <p>Executive Director, <br />
                                        Human Resources Finvasia</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-12">
                        <div class="ourDirectorsCol bg-light-sky">
                            <div class="ourDirectors-img">
                                <v-lazy-image class="img-fluid" width="250" height="216"
                                    src="/assets/images/ourleadershipimg/sandy-samra.png" alt="Sandy Samra"
                                    title="Sandy Samra" />
                            </div>
                            <div class="managment-info-wrap">
                                <a href="https://www.linkedin.com/in/sandy-samra-finvasia/" class="managment-linked-in-link" target="_blank">
                                    <img src="/assets/images/linked-icon.svg" alt="Linked In Icon">
                                </a>
                                <div class="managment-Name">
                                    <h2>Sandy Samra</h2>
                                    <p>Executive Director, <br />
                                        General Management Finvasia</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row row-cols-5 our-leader-Wrap">
                    <div class="cols" v-for="list in newList" :key="list.id">
                        <div class="ourDirectorsCol bg-light-grey">
                            <div class="ourDirectors-img">
                                <v-lazy-image class="img-fluid" width="189" height="164"
                                    :src="list.img" :alt="list.name" :title="list.name" />
                            </div>
                            <div class="managment-info-wrap">
                                <div class="managment-Name text-center">
                                    <h2>{{list.name}}</h2>
                                    <p v-html="list.description"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="your-services-section">
            <div class="container-fluid">
                <div class="row p-0 m-0">
                    <div class="col-lg-12 col-sm-12 mb-5">
                        <div class="your-services-heading">
                            <h2 class="text-center">450 <span class="themetextColor">Extraordinary </span>
                                <span>People at your service</span></h2>
                            <p class="text-center lh-190 mx-auto">Our team comprises over 450 employees that work
                                in our physical offices across UK, Greece, Cyprus, India, South Africa, Mauritius,
                                Dubai, Japan and Australia </p>
                        </div>
                    </div>
                    <div class="col-lg-12 col-sm-12">
                        <div class="marquee-row mobile-emp-slider-col">
                            <Vue3Marquee class="animation d-flex" :duration="50" :pauseOnClick="true">
                                
                                <li>
                                    <div class="team-img-col">
                                        <div class="team-img-col-iner">
                                            <img src="/assets/images/team-images/pushender.png" alt="Pushpinder Singh"
                                                title="Pushpinder Singh">
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="team-img-col">
                                        <div class="team-img-col-iner">
                                            <img src="/assets/images/team-images/gurjot-sodhi.png" alt="Gurjot Sodhi"
                                                title="Gurjot Sodhi">
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="team-img-col">
                                        <div class="team-img-col-iner">
                                            <img src="/assets/images/team-images/pavlina-constantinou.png"
                                                alt="Pavlina Constantinou" title="Pavlina Constantinou">
                                        </div>
                                    </div>
                                </li>
                                <li>

                                    <div class="team-img-col">
                                        <div class="team-img-col-iner">
                                            <img src="/assets/images/team-images/shashi-thakur.png"
                                                alt="Shashi Thakur" title="Shashi Thakur">
                                                
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="team-img-col">
                                        <div class="team-img-col-iner">
                                            <img src="/assets/images/team-images/rakesh-kumar-saini.jpg" alt="Rakesh Kumar Saini" title="Rakesh Kumar Saini">
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="team-img-col">
                                        <div class="team-img-col-iner">
                                            <img src="/assets/images/team-images/mukesh-thakur.png" alt="Mukesh Thakur"
                                                title="Mukesh Thakur">
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="team-img-col">
                                        <div class="team-img-col-iner">
                                            <img src="/assets/images/team-images/durga-paswan.png" alt="Durga Paswan"
                                                title="Durga Paswan">
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="team-img-col">
                                        <div class="team-img-col-iner">
                                            <img src="/assets/images/team-images/amit-kumar.png" alt="Amit kumar"
                                                title="Amit kumar">
                                        </div>
                                    </div>
                                </li>
                                <!-- <li>
                                    <div class="team-img-col">
                                        <div class="team-img-col-iner">
                                            <img src="/assets/images/team-images/Katia-Polychronou.png" alt="Katia Polychronou"
                                                title="Katia Polychronou">
                                        </div>
                                    </div>
                                </li> -->
                                <li>
                                    <div class="team-img-col">
                                        <div class="team-img-col-iner">
                                            <img src="/assets/images/team-images/Nair.png" alt="Chandrasekharan Nair" title="Chandrasekharan Nair">
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="team-img-col">
                                        <div class="team-img-col-iner">
                                            <img src="/assets/images/team-images/Gurjot-Galwatti.png" alt="Gurjot Galwatti" title="Gurjot Galwatti">
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="team-img-col">
                                        <div class="team-img-col-iner">
                                            <img src="/assets/images/team-images/Harpreet-Singh.png" alt="Harpreet Singh" title="Harpreet Singh">
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="team-img-col">
                                        <div class="team-img-col-iner">
                                            <img src="/assets/images/team-images/Kunal.png" alt="Kunal" title="Kunal">
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="team-img-col">
                                        <div class="team-img-col-iner">
                                            <img src="/assets/images/team-images/gurpreet-ahluwalia.png" alt="gurpreet ahluwalia" title="Gurpreet Ahluwalia">
                                        </div>
                                    </div>
                                </li>
                               
                            </Vue3Marquee>
                            <Vue3Marquee class="animation d-flex" :duration="50" :pauseOnClick="true">
                                <li>
                                    <div class="team-img-col">
                                        <div class="team-img-col-iner">
                                            <img src="/assets/images/team-images/Kultar-Dhiman.png" alt="Kultar Dhiman"
                                                title="Kultar Dhiman">
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="team-img-col">
                                        <div class="team-img-col-iner">
                                            <img src="/assets/images/team-images/kapil-abrol.png" alt="Kapil Abrol"
                                                title="Kapil Abrol">
                                        </div>
                                    </div>
                                </li>
                                
                                <!-- <li>
                                    <div class="team-img-col">
                                        <div class="team-img-col-iner">
                                            <img src="/assets/images/team-images/Sneha-Garg.png" alt="Neha Garg"
                                                title="Neha Garg">
                                        </div>
                                    </div>
                                </li> -->
                                <li>
                                    <div class="team-img-col">
                                        <div class="team-img-col-iner">
                                            <img src="/assets/images/team-images/Pooja-Sharma.png" alt="Pooja Sharma"
                                                title="Pooja Sharma">
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="team-img-col">
                                        <div class="team-img-col-iner">
                                            <img src="/assets/images/team-images/Rajesh-Kumar.png" alt="Rajesh kumar"
                                                title="Rajesh kumar">
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="team-img-col">
                                        <div class="team-img-col-iner">
                                            <img src="/assets/images/team-images/Akanksha-Chabbra.png" alt="Akanksha"
                                                title="Akanksha">
                                        </div>
                                    </div>
                               </li>
                               <!-- <li>
                                <div class="team-img-col">
                                    <div class="team-img-col-iner">
                                        <img src="/assets/images/team-images/Inder-Preet-Singh.png" alt="Inder Preet Singh"
                                            title="Inder Preet Singh">
                                    </div>
                                </div>
                               </li> -->
                               <li>
                                <div class="team-img-col">
                                    <div class="team-img-col-iner">
                                        <img src="/assets/images/team-images/team-member13.png" alt="Geetika" title="Geetika">
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="team-img-col">
                                    <div class="team-img-col-iner">
                                        <img src="/assets/images/team-images/team-member14.png" alt="Akshay" title="Akshay">
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="team-img-col">
                                    <div class="team-img-col-iner">
                                        <img src="/assets/images/team-images/aditi.png" alt="Aditi" title="Aditi">
                                    </div>
                                </div>
                            </li>
                            <!-- <li>
                                <div class="team-img-col">
                                    <div class="team-img-col-iner">
                                        <img src="/assets/images/team-images/Nick.png" alt="Nick" title="Nick">
                                    </div>
                                </div>
                            </li> -->
                            <li>
                                <div class="team-img-col">
                                    <div class="team-img-col-iner">
                                        <img src="/assets/images/team-images/Vishnu-Poonia.png" alt="Vishnu Poonia" title="Vishnu Poonia">
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="team-img-col">
                                    <div class="team-img-col-iner">
                                        <img src="/assets/images/team-images/Sarah-Bennett.png" alt="Sarah Bennett" title="Sarah Bennett">
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="team-img-col">
                                    <div class="team-img-col-iner">
                                        <img src="/assets/images/team-images/sachin-mandal.png" alt="Sachin Mandal" title="Sachin Mandal">
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="team-img-col">
                                    <div class="team-img-col-iner">
                                        <img src="/assets/images/team-images/avneet-singh.png" alt="Avneet Singh" title="Avneet Singh">
                                    </div>
                                </div>
                            </li>

                              
                            </Vue3Marquee>
                             <Vue3Marquee class="animation d-flex" :duration="50" :pauseOnClick="true">
                            
                                <li>
                                    <div class="team-img-col">
                                        <div class="team-img-col-iner">
                                            <img src="/assets/images/team-images/kapil.png" alt="Kapil"
                                                title="Kapil">
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="team-img-col">
                                        <div class="team-img-col-iner">
                                            <img src="/assets/images/team-images/team-member20.png" alt="Punit Sharma"
                                                title="Punit Sharma">
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="team-img-col">
                                        <div class="team-img-col-iner">
                                            <img src="/assets/images/team-images/kartik-singh.png" alt="Kartik Singh" title="Kartik Singh">
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="team-img-col">
                                        <div class="team-img-col-iner">
                                            <img src="/assets/images/team-images/keshav-bhandari.png" alt="Keshav Bhandari" title="Keshav Bhandari">
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="team-img-col">
                                        <div class="team-img-col-iner">
                                            <img src="/assets/images/team-images/manish-chauhan.png" alt="Manish Chauhan" title="Manish Chauhan">
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="team-img-col">
                                        <div class="team-img-col-iner">
                                            <img src="/assets/images/team-images/kshitij-chopra.png" alt="Kshitij Chopra" title="Kshitij Chopra">
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="team-img-col">
                                        <div class="team-img-col-iner">
                                            <img src="/assets/images/team-images/gourav-sachdeva.png" alt="Gourav Sachdeva" title="Gourav Sachdeva">
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="team-img-col">
                                        <div class="team-img-col-iner">
                                            <img src="/assets/images/team-images/team-member11.png" alt="Ashish Kumar" title="Ashish Kumar">
                                        </div>
                                    </div>
                                </li>
                                <!-- <li>
                                    <div class="team-img-col">
                                        <div class="team-img-col-iner">
                                            <img src="/assets/images/team-images/mohammed.png" alt="Mohammed" title="Mohammed">
                                        </div>
                                    </div>
                                </li> -->
                                <li>
                                    <div class="team-img-col">
                                        <div class="team-img-col-iner">
                                            <img src="/assets/images/team-images/prerna-dewan.png" alt="Prerna Dewan" title="Prerna Dewan">
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="team-img-col">
                                        <div class="team-img-col-iner">
                                            <img src="/assets/images/team-images/Avnika-Walia.png" alt="Avnika Walia" title="Avnika Walia">
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="team-img-col">
                                        <div class="team-img-col-iner">
                                            <img src="/assets/images/team-images/piyush-rawat.png" alt="Piyush Rawat" title="Piyush Rawat">
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="team-img-col">
                                        <div class="team-img-col-iner">
                                            <img src="/assets/images/team-images/team-member21.png" alt="Charu Ahuja" title="Charu Ahuja">
                                        </div>
                                    </div>
                                </li>
                                
                                <li>
                                    <div class="team-img-col">
                                        <div class="team-img-col-iner">
                                            <img src="/assets/images/team-images/deepak-sharma.png" alt="Deepak Sharma" title="Deepak Sharma">
                                        </div>
                                    </div>
                                </li>
                            </Vue3Marquee> 
                        </div>

                        <div class="manymore-link">
                            <a href="javascript:void(0)" class="pointer-none">& many more...</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="team-gallry-section">
            <div class="container cstm">
                <div class="row">
                    <div class="col-lg-7 col-md-6 col-sm-12 hideinMobile">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="team-gallery-img">
                                    <v-lazy-image class="img-fluid" width="442" height="316"
                                        src="/assets/images/team-images/gallery-img1.png" alt="Team" />
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="team-gallery-img">
                                    <v-lazy-image class="img-fluid" width="442" height="316"
                                        src="/assets/images/team-images/gallery-img2.png" alt="Team" />
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="team-gallery-img">
                                    <v-lazy-image class="img-fluid" width="442" height="316"
                                        src="/assets/images/team-images/gallery-img3.png" alt="Team" />
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="team-gallery-img">
                                    <v-lazy-image class="img-fluid" width="442" height="316"
                                        src="/assets/images/team-images/gallery-img4.png" alt="Team" />
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-5 col-sm-12 ">
                                <div class="team-gallery-img  padding-bottom-zero">
                                    <v-lazy-image class="img-fluid" width="364" height="255"
                                        src="/assets/images/team-images/gallery-img5.png" alt="Team" />
                                </div>
                                <div class="team-gallery-img pt-4 pb-0">
                                    <v-lazy-image class="img-fluid" width="364" height="255"
                                        src="/assets/images/team-images/gallery-img6.png" alt="Team" />
                                </div>
                            </div>
                            <div class="col-lg-7 col-md-7 col-sm-12">
                                <div class="team-gallery-img full-height padding-bottom-zero">
                                    <v-lazy-image class="img-fluid" width="520" height="526"
                                        src="/assets/images/team-images/gallery-img7.png" alt="Team" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mobileEmg-gallery">
                        <div class="ulList">
                            <carousel :settings="settings" :breakpoints="breakpoints" :mouseDrag="true"
                                :touchDrag="true" :transition="1000" :itemsToShow="1" :itemsToScroll="1">
                                <slide :key="1">
                                    <div class="listCol">
                                        <div class="team-gallery-img">
                                            <img src="/assets/images/team-images/gallery-img1.png" alt="Team">
                                        </div>
                                    </div>
                                </slide>
                                <slide :key="2">
                                    <div class="listCol">
                                        <div class="team-gallery-img">
                                            <img src="/assets/images/team-images/gallery-img2.png" alt="Team">
                                        </div>
                                    </div>
                                </slide>
                                <slide :key="3">
                                    <div class="listCol">
                                        <div class="team-gallery-img">
                                            <img src="/assets/images/team-images/gallery-img3.png" alt="Team">
                                        </div>
                                    </div>
                                </slide>
                                <slide :key="3">
                                    <div class="listCol">
                                        <div class="team-gallery-img">
                                            <img src="/assets/images/team-images/gallery-img4.png" alt="Team">
                                        </div>
                                    </div>
                                </slide>
                                <slide :key="4">
                                    <div class="listCol">
                                        <div class="team-gallery-img">
                                            <img src="/assets/images/team-images/gallery-img5.png" alt="Team">
                                        </div>
                                    </div>
                                </slide>
                                <slide :key="5">
                                    <div class="listCol">
                                        <div class="team-gallery-img">
                                            <img src="/assets/images/team-images/gallery-img6.png" alt="Team">
                                        </div>
                                    </div>
                                </slide>
                                <slide :key="6">
                                    <div class="listCol">
                                        <div class="team-gallery-img">
                                            <img src="/assets/images/team-images/gallery-img7.png" alt="Team">
                                        </div>
                                    </div>
                                </slide>
                                <template #addons>
                                    <navigation />
                                    <pagination />
                                </template>
                            </carousel>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-6 col-sm-12 flexRow">
                        <div class="team-gallery-img hideinMobile">
                            <img class="img-fluid" width="642" height="906"
                                src="/assets/images/team-images/gallery-img8.png" alt="Team">
                        </div>
                        <div class="team-office-disc hide-mobile">
                            <h3>We love our jobs but it is not always just work.</h3>
                            <p>We foster genuine connections, embrace diversity and inclusion, and empower our team to take big leaps and try new things. Join our team and work alongside the brightest professionals across various industries, from finance to healthcare and beyond.</p>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 hide-desktop">
                        <div class="team-office-disc">
                            <h3>We love our jobs but it is not always just work.</h3>
                            <p>We foster genuine connections, embrace diversity and inclusion, and empower our team to take big leaps and try new things. Join our team and work alongside the brightest professionals across various industries, from finance to healthcare and beyond.</p>
                        </div>
                    </div>
                </div>
                <div class="row marginTop20">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="team-gallery-img">
                            <img class="img-fluid" width="776" style="max-height: 570px;"
                                src="/assets/images/team-images/gallery-img9.png" alt="Team">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="team-gallery-img">
                            <img class="img-fluid" width="776" src="/assets/images/team-images/gallery-img10.png"
                                alt="Team">
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import "vue3-carousel/dist/carousel.css";
    import { Carousel, Slide, Navigation, } from "vue3-carousel";
    import { Vue3Marquee } from 'vue3-marquee'
    import {useMainStore} from"../../store/index.ts"
    export default {
        name: 'Management',
        setup(){
            const store= useMainStore();
            return {store};
        },
        data() {
            return {
                Tab: 1,
                settings: {
                    snapAlign: "center",
                    itemsToScroll: 1,
                    autoplay: 0,
                    wrapAround: 'true'
                },
                is_country_cyprus:false,
                newList:[]
            }
        },
        
        methods: {
            changeTab(val) {
                this.Tab = val;
            },
            // randomList(rand){
            //     let notCyprusList = rand.filter(e=>e.isCyprus == 'false')
            //     let notAllList = notCyprusList.sort(function(){return 0.5 - Math.random()});
            //     let allList = rand.sort(function(){return 0.5 - Math.random()});
            //     try{
            //         this.store.getcountrycode().then(res=>{
            //            if(res == true){
            //                 if(this.store.not_cyprus_brand){
            //                     return notAllList
            //                 } else {
            //                     return allList
            //                 }
            //             } else {
            //                 return allList
            //             }
                        
            //         })
            //     }catch{
            //         return allList
            //     }
                
            // }
            randomList() {
                let _that = this;
                let rand = this.static_vars.uppManage;
                let notCyprusList = rand.filter(e=>e.isCyprus == 'false')
                let notAllList = notCyprusList.sort(function(){return 0.5 - Math.random()});
                let allList = rand.sort(function(){return 0.5 - Math.random()});
                try {
                    let listCyprus = this.store.getcountrycode();
                    listCyprus.then(function(){
                        console.log("this.not_cyprus_brand", this.not_cyprus_brand)
                        console.log("listCyprus", listCyprus)

                        if(this.store.not_cyprus_brand){
                            _that.newList=notAllList;
                        } else {
                            _that.newList=allList;
                        }
                    })
                    .catch(function(){
                        _that.newList=  allList
;
                    })
                } catch (error) {
                    _that.newList=allList;
                }
            },


        },
        created() {
            this.randomList();
            // this.store.getcountrycode().then(res=>{
            //     if(res == true && this.store.not_cyprus_brand){
            //         this.is_country_cyprus = false
            //     }else{
            //         this.is_country_cyprus = true
            //     }
            // })
            
            if (window.screen.width <= 767) {
                // console.log("$(window).width();===========", window.screen.width)
                this.Tab = ''
            }
            
        },
        components: {
            Vue3Marquee,
            Carousel,
            Slide,
            Navigation,
        }
    }

</script>