import { defineStore } from "pinia";
// import * as API from '@/api/rx-factory'
import * as ENDPOINTS from '@/api/endpoints'
import axios from 'axios';

export const useMainStore = defineStore('main', {
  persist: {
    key: 'storekey',
    storage: globalThis.localStorage,
    paths: ['user'],
  },
  state: () => ({
    loading:false,
    current_countries_IN: {"IN": "India"},
    current_countries_AE: {"AE": "Dubai"},
    current_countries_cyprus: {"CY": "Cyprus"},
    not_cyprus_brand: false,
    current_location_IN:false,
    europian_country: false,
    current_location_AE: false,
    eu_country_list: {
        "AT": '"Austria"',
        "BE": '"Belgium"',
        "BG": '"Bulgaria"',
        "CY": '"Cyprus"',
        "CZ": '"Czechia"',
        "DE": '"Germany"',
        "DK": '"Denmark"',
        "EE": '"Estonia"',
        "ES": '"Spain"',
        "FI": '"Finland"',
        "FR": '"France"',
        "GR": '"Greece"',
        "HR": '"Croatia"',
        "HU": '"Hungary"',
        "IE": '"Ireland"',
        "IT": '"Italy"',
        "LT": '"Lithuania"',
        "LU": '"Luxembourg"',
        "LV": '"Latvia"',
        "MT": '"Malta"',
        "NL": '"Netherlands"',
        "PL": '"Poland"',
        "PT": '"Portugal"',
        "RO": '"Romania"',
        "SE": '"Sweden"',
        "SI": '"Slovenia"',
        "SK": '"Slovakia"',
        "CH": '"Switzerland"',
        "GB": '"United Kingdom"'
    },
  }),
  getters: {
    
  },
  actions: {
    getcountrycode(){     
      return new Promise((resolve, reject) => {
        fetch('https://api.aaafx.com/get/geo').then(response => {
          if (response.ok) {
              return response.text();
          } else {
              throw new Error('HTTP Error ' + response.status);
          }
          }).then(country => {
              if (country != '') {
                  if (this.eu_country_list[country]) { 
                    this.not_cyprus_brand= true
                    resolve(this.not_cyprus_brand)
                  }else if(this.eu_country_list[country]){
                    this.europian_country= true
                    resolve(this.not_cyprus_brand)
                  }else if(this.current_countries_IN[country]){
                    this.current_location_IN = true
                    resolve(this.current_location_IN)
                  }
                  else if(this.current_countries_AE[country]){
                    this.current_location_AE = true
                    resolve(this.current_location_AE)
                  }
                  else {
                    resolve(country)
                  }
              }
            }).catch(function (error) {
                reject(error);
        });
      });
    },
    getNewsList(params = {}, loader = false,id) {
      if (loader) {
        this.loading = true;
      }
      return new Promise((resolve, reject) => {
        let ep = ENDPOINTS.EP_GET_NEWS_LIST;
         ep = ep.replace('{{category}}',id)
         axios.get(ep).then((response) => {
            this.loading = false;
            resolve(response);
          }).catch((error) => {
            this.loading = false;
            reject(error);
          });
      });
    },
    contactus(params){
        return new Promise((resolve, reject) => {
            axios.post(ENDPOINTS.EP_CONTACT_US_ENQUIRY, params.globalParams).then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            });
        });
    },
  },
});
